module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"release":"le-bon-site@d7769cc82f436b8a8692d8c3553222426c04abb6","dsn":"https://a0f7faf66fbb47a5b50b75d6c26940d0@o466748.ingest.sentry.io/5481315","tracesSampleRate":1,"browserTracingOptions":{"traceXHR":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Le bon site, par Spintank","short_name":"Le bon site, par Spintank","start_url":"/","background_color":"#FEFC71","theme_color":"#000","display":"standalone","orientation":"portrait","icon":"src/images/favicon.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3d8f9ee81ef941909fcbfe48a764e51a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
