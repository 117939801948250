// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-components-edito-page-js": () => import("./../../../src/components/PageComponents/EditoPage.js" /* webpackChunkName: "component---src-components-page-components-edito-page-js" */),
  "component---src-components-page-components-site-page-js": () => import("./../../../src/components/PageComponents/SitePage.js" /* webpackChunkName: "component---src-components-page-components-site-page-js" */),
  "component---src-components-questions-question-boolean-question-boolean-js": () => import("./../../../src/components/Questions/QuestionBoolean/QuestionBoolean.js" /* webpackChunkName: "component---src-components-questions-question-boolean-question-boolean-js" */),
  "component---src-components-questions-question-dragdrop-question-drag-drop-js": () => import("./../../../src/components/Questions/QuestionDragdrop/QuestionDragDrop.js" /* webpackChunkName: "component---src-components-questions-question-dragdrop-question-drag-drop-js" */),
  "component---src-components-questions-question-end-question-end-js": () => import("./../../../src/components/Questions/QuestionEnd/QuestionEnd.js" /* webpackChunkName: "component---src-components-questions-question-end-question-end-js" */),
  "component---src-components-questions-question-interstice-question-interstice-js": () => import("./../../../src/components/Questions/QuestionInterstice/QuestionInterstice.js" /* webpackChunkName: "component---src-components-questions-question-interstice-question-interstice-js" */),
  "component---src-components-questions-question-scale-question-scale-js": () => import("./../../../src/components/Questions/QuestionScale/QuestionScale.js" /* webpackChunkName: "component---src-components-questions-question-scale-question-scale-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-motto-index-js": () => import("./../../../src/pages/motto/index.js" /* webpackChunkName: "component---src-pages-motto-index-js" */),
  "component---src-pages-sites-index-js": () => import("./../../../src/pages/sites/index.js" /* webpackChunkName: "component---src-pages-sites-index-js" */)
}

